import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import AuthHeader from '../Header/AuthHeader/AuthHeader';
import AppWrapper, { ContentWrapper } from './Layout.styled';

export const Layout: React.FunctionComponent<{}> = ({
  children,
}) => {
  const pathname = useLocation().pathname;
  let layoutBg = '#ffffff';

  if (pathname === '/') {
    layoutBg = '#fcfcfc';
  }

  return (
    <AppWrapper className="light" style={{ backgroundColor: layoutBg }}>
      {pathname === '/login' || pathname === '/signup' ? (
        <AuthHeader pathname={pathname} />
      ) : (
        <Header />
      )}

      <ContentWrapper>{children}</ContentWrapper>

      {pathname === '/login' || pathname === '/signup' ? '' : <Footer />}
    </AppWrapper>
  );
};
