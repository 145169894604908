import React from 'react';
import Container from '../Container/Container';
import Section, { Backdrop, Subtitle, Title } from './PageTitle.styled';

export type PageTitleProps = {
  title: React.ReactNode | undefined;
  subtitle?: React.ReactNode;
  backdrop?: boolean;
  bgColor?: string;
};

const PageTitle = ({ title, subtitle, backdrop, bgColor }: PageTitleProps) => {
  return (
    <Section style={{ backgroundColor: bgColor }}>
      <Container>
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Container>
      {backdrop && <Backdrop></Backdrop>}
    </Section>
  );
};

PageTitle.defaultProps = {
  backdrop: true,
};

export default PageTitle;
