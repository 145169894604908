import React from 'react';

export const ServiceIcon: React.FC<{ type: string | null }> = ({ type }) => {
  switch (type) {
    case 'jira':
    case 'jira-server':
    case 'jira-data-center':
      return <span>Jira</span>
    case 'confluence':
    case 'confluence-server':
    case 'confluence-data-center':
      return <span>Conf</span>
    case 'bitbucket':
    case 'bitbucket-server':
    case 'bitbucket-data-center':
      return <span>Bit</span>
    case 'sonarqube':
      return <span>Sonar</span>
    case 'nexus':
      return <span>Nexus</span>

    default:
      return <span>Other</span>
  }
}