import React from 'react';
import Menu, { MenuItem, MenuLink } from './TopMenu.style';

const menuItems = [
  {
    id: 1,
    label: 'Webpage',
    path: 'https://www.linkyard.cloud',
  },
  {
    id: 2,
    label: 'Run My Accounts',
    path: 'https://my.runmyaccounts.com',
  },
  {
    id: 3,
    label: 'Jira',
    path: 'https://jira.linkyard.ch/secure/RapidBoard.jspa?rapidView=36',
  },
];

type MenuProps = {
  className?: string;
  onClick?: () => void;
};

const TopMenu = ({ className, onClick }: MenuProps) => {
  return (
    <Menu className={className}>
      {menuItems.map(item => (
        <MenuItem key={`top-menu--item${item.id}`} onClick={onClick}>
          <MenuLink href={item.path}>{item.label}</MenuLink>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default TopMenu;
