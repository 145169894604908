import React from 'react';
import SvgIcon from '../../UiElements/SvgIcon/SvgIcon';
import { ReactComponent as LogoSvg } from '../../../images/logo.svg';
import Wrapper, { LogoText } from './Logo.styled';
import { Link } from 'react-router-dom';

type LogoProps = {
  path?: string;
  style?: React.CSSProperties;
};

const Logo = ({ path, style }: LogoProps) => {
  return (
    <Wrapper className="logo" style={style}>
      <Link to={path || "/"}>
        <SvgIcon><LogoSvg /></SvgIcon>
      </Link>
      <LogoText>linkyard.cloud</LogoText>
    </Wrapper>
  )
};

export default Logo;
