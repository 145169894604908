import gql from 'graphql-tag'
import React from 'react'
import { useSubscription } from 'react-apollo'
import { useParams, Link } from 'react-router-dom'
import { GraphqlError } from '../../components/Graphql/GraphqlError'
import { GraphqlLoading } from '../../components/Graphql/GraphqlLoading'
import { PageWithTitle } from '../../components/Page/Page'
import NotFound from '../../components/UiElements/NotFound/NotFound'
import { GetService } from './types/GetService'
import { ServiceIcon } from '../../components/Service/ServiceIcon'
import { Icon } from './Service.styled'

const GET_SERVICE = gql`
  query GetService($key: String!) {
    service_by_pk(key: $key) {
      key
      type
      customer {
        key
        name
      }
    }
  }
`

export const Service = () => {
  const { key } = useParams<{ key: string }>()
  const { loading, error, data } = useSubscription<GetService, {}>(GET_SERVICE, {
    variables: {
      key: key
    }
  })
  if (loading) return <GraphqlLoading />
  if (error) return <GraphqlError error={error} />
  const service = data?.service_by_pk
  if (!service) return <NotFound />

  const title = (
    <span style={{ display: 'flex' }}>
      <Icon style={{ fontSize: 'small' }}><ServiceIcon type={service.type} /></Icon>
      <div style={{ marginLeft: '0.4em' }}>Service {service.key}</div>
    </span>)
  return (
    <PageWithTitle title={title} >
      <div>
        <span>
          {service.type} for <Link to={`/customer/${service.customer.key}`}>{service.customer.name || service.customer.key}</Link>
        </span>
      </div>
    </PageWithTitle >
  )
}