import React from 'react'
import { Alert } from 'baseui/icon'
import { type } from 'os'
import { styled } from 'styletron-react'

type type = 'warn' | 'error'
interface Props {
    type: type
    hanging?: boolean
}

const iconFor = (type: type) => {
    switch (type) {
        case 'warn': return <Alert color="#ffa500" />
        case 'error': return <Alert color="red" />
    }
}

const Container = styled('span', {
    position: 'relative',
    top: '3px',
})
const HangingContainer = styled(Container, {
    marginRight: '-1.2em',
})
export const Indicator: React.FC<Props> = ({ type, hanging }) => {
    const X = hanging ? HangingContainer : Container
    return <X>{iconFor(type)}</X>
}