import React, { useState } from 'react'
import { Input } from 'baseui/input'
import { SaveButton, EditButton } from '../Button/Button'

interface Props {
  value: string
  save: (value: string) => void
  style?: React.CSSProperties
}

export const InlineEditable: React.FC<Props> = ({ value, save, style }) => {
  const [editing, setEditing] = useState<boolean>(false)
  const [currentValue, setCurrentValue] = useState<string | null>(null)
  const edit = () => {
    setCurrentValue(value)
    setEditing(true)
  }
  const doSave = () => {
    if (currentValue)
      save(currentValue)
    setCurrentValue(null)
    setEditing(false)
  }
  // TODO support ENTER for save
  if (editing) {
    return (
      <div style={{ display: 'flex', ...style }}>
        <Input
          value={currentValue || ''}
          onChange={(event) => setCurrentValue(event.currentTarget.value)}
          onKeyPress={(event) => event.which === 13 ? doSave() : {}}
          autoFocus={true}
          overrides={{}}
        />
        <SaveButton onClick={doSave} style={{ marginLeft: '7px' }} />
      </div>
    )
  } else {
    return (<>{value} <EditButton onClick={edit} style={{ marginLeft: '7px' }} /></>)
  }
}