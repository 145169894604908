import { styled } from "baseui";

export const Button = styled('button', ({ $theme }) => ({
  display: 'inline',
  alignItems: 'center',
  backgroundColor: 'transparent',
  borderWidth: 0,
  cursor: 'pointer',
  padding: '0',
  color: $theme.colors.primaryA,
  ':focus': {
    outline: 'none',
  },
}));
