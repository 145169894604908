import { Alert, Spinner } from 'baseui/icon'
import { StatefulTooltip } from 'baseui/tooltip'
import gql from 'graphql-tag'
import moment from 'moment'
import React from 'react'
import { useSubscription } from 'react-apollo'
import { AvailabilityPercentage } from '../../components/Service/AvailabilityPercentage'
import { formatCron, generateCronIntervals, Interval } from './periodicity'
import { AvailabilityCell, IntervalDetailHead, IntervalNameHead, ItemNameHead, SlaContract, SlaDetails, SlaInfos, SlrContent, SlrPeriod, SlrReporting, SlrTable, SupportCell, TicketList, TooltipContent, TooltipSection } from './ServiceLevelReport.styled'
import { GetServiceAvailability, GetServiceAvailabilityVariables } from './types/GetServiceAvailability'
import { GetSupportTimes, GetSupportTimesVariables } from './types/GetSupportTimes'
import { ServiceLevelReportParts } from './types/ServiceLevelReportParts'
import { Indicator } from '../../components/Indicator/Indicator'

export const ServiceLevelReportFragment = gql`
  fragment ServiceLevelReportParts on serviceLevelReporting {
    id
    periodicity
    services {
      service {
        key
        type
        hostname
        contractItem {
          name
        }
      }
    }
    sla {
        contract {
            key
            title
        }
        name
        details
    }
    servicedesks_aggregate {
      aggregate {
        count
      }
    }
  }
`

export const GET_SERVICE_AVAILABILITY = gql`
  subscription GetServiceAvailability($key: String!, $from: timestamptz, $until: timestamptz) {
    service_by_pk(key: $key) {
      availability(args: {from: $from, until: $until}) {
        up_fraction
        down_seconds
        unaccounted_seconds
      }
    }
  }
`

export const GET_SUPPORT_TIMES = gql`
  subscription GetSupportTimes($id: uuid!, $from: timestamptz!, $until: timestamptz!) {
    serviceLevelReporting_by_pk(id: $id) {
      servicedesks {
        project
        customer_servicedesk_name
        tickets_ok: tickets_aggregate(where: {sla_ok: {_eq: true}, closed: {_eq: true}, created_at: {_gt: $from}, _and: {created_at: {_lt: $until}}}) {
          aggregate {
            count
          }
        }
        tickets_violated: tickets(where: {sla_ok: {_eq: false}, closed: {_eq: true}, created_at: {_gt: $from}, _and: {created_at: {_lt: $until}}}) {
          key
          summary
        }
        tickets_open: tickets(where: {closed: {_eq: false}, created_at: {_gt: $from}, _and: {created_at: {_lt: $until}}}) {
          key
          summary
        }
      }
    }
  }
`

interface ServiceAvailabilityProps {
    serviceKey: string
    interval: Interval
}
const ServiceAvailability: React.FC<ServiceAvailabilityProps> = ({ serviceKey, interval }) => {
    const { loading, error, data } = useSubscription<GetServiceAvailability, GetServiceAvailabilityVariables>(GET_SERVICE_AVAILABILITY, {
        variables: {
            key: serviceKey,
            from: interval.start.format(),
            until: interval.end.format(),
        }
    })
    if (loading) return <Spinner />
    if (error) return <Indicator type="error" />
    const avails = data?.service_by_pk?.availability
    const avail = avails && avails.length > 0 && avails[0]
    if (!avail) return <Indicator type="error" />
    const name = `${serviceKey} ${interval.name} (${interval.start.format('DD.MM.YYYY')} - ${interval.end.format('DD.MM.YYYY')})`
    return <AvailabilityPercentage periodName={name} data={avail} hangingIndicator={true}/>
}

interface SupportTimesProps {
    slrId: string
    interval: Interval
}
const SupportTimes: React.FC<SupportTimesProps> = ({ slrId, interval }) => {
    const { loading, error, data } = useSubscription<GetSupportTimes, GetSupportTimesVariables>(GET_SUPPORT_TIMES, {
        variables: {
            id: slrId,
            from: interval.start.format(),
            until: interval.end.format(),
        }
    })
    if (loading) return <Spinner />
    if (error) return <Alert color="red" />
    const sds = (data?.serviceLevelReporting_by_pk?.servicedesks || [])
    const countOk = sds.flatMap(sd => sd.tickets_ok).reduce((a, b) => a + (b.aggregate?.count || 0), 0)
    const violated = sds.flatMap(sd => sd.tickets_violated)
    const open = sds.flatMap(sd => sd.tickets_open)
    const totalCount = countOk + violated.length

    const tooltip = (<TooltipContent>
        <TooltipSection>{sds.length} Service-Desk Customers assigned</TooltipSection>
        {violated.length > 0 && <TooltipSection>
            Violations:
            <TicketList>
                {violated.map(v => <li key={v.key}>{v.key} - {v.summary}</li>)}
            </TicketList>
        </TooltipSection>}
        {open.length > 0 && <TooltipSection>
            Still open:
            <TicketList>
                {open.map(v => <li key={v.key}>{v.key} - {v.summary}</li>)}
            </TicketList>
        </TooltipSection>}
    </TooltipContent>)
    return (
        <StatefulTooltip content={tooltip} triggerType="hover">
            <span>
                {(violated.length > 0 ?
                    <span>violated on {violated.length} out of {totalCount}</span> :
                    <span>ok ({countOk} Tickets)</span>)}
                {(open.length > 0) && <Indicator type="warn" hanging={true} />}
            </span>
        </StatefulTooltip>)
}

interface Props {
    slr: ServiceLevelReportParts
}

export const ServiceLevelReport: React.FC<Props> = ({ slr }) => {
    const intervals = generateCronIntervals(slr.periodicity, 1, 2)
    const currentInterval = intervals[intervals.length - 1]
    currentInterval.end = moment()
    currentInterval.name = `${currentInterval.name} (current)`
    intervals.reverse()

    const services = slr.services.flatMap(s => s.service)
    const hasServiceDesk = (slr.servicedesks_aggregate.aggregate?.count || 0) > 0
    return (
        <SlrContent>
            <h3>{slr.sla.contract.key} {slr.sla.name}</h3>
            <SlaInfos>
                <SlaContract>in Contract {slr.sla.contract.key} - {slr.sla.contract.title}</SlaContract>
                {slr.sla.details && <SlaDetails>{slr.sla.details}</SlaDetails>}
                <SlrPeriod>Reporting due: {formatCron(slr.periodicity)}</SlrPeriod>
            </SlaInfos>
            <SlrReporting>
                <SlrTable>
                    <thead>
                        <tr>
                            <th />
                            {intervals.map(i => <IntervalNameHead key={i.name}>{i.name}</IntervalNameHead>)}
                        </tr>
                        <tr>
                            <th />
                            {intervals.map(i => <IntervalDetailHead key={i.name}>{i.start.format('DD.MM.YYYY')} - {i.end.format('DD.MM.YYYY')}</IntervalDetailHead>)}
                        </tr>
                    </thead>
                    <tbody>
                        {services.map(s => <tr key={slr.id + s.key}>
                            <ItemNameHead>{s.contractItem?.name || s.key}</ItemNameHead>
                            {intervals.map(i => <AvailabilityCell key={i.name}><ServiceAvailability serviceKey={s.key} interval={i} /></AvailabilityCell>)}
                        </tr>)}
                        {hasServiceDesk && <tr>
                            <ItemNameHead>Support Tickets</ItemNameHead>
                            {intervals.map(i => <SupportCell key={i.name}><SupportTimes slrId={slr.id} interval={i} /></SupportCell>)}
                        </tr>}
                    </tbody>
                </SlrTable>
            </SlrReporting>
        </SlrContent>)
}