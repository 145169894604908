import { Avatar } from 'baseui/avatar';
import { Block } from 'baseui/block';
import { StatefulPopover } from 'baseui/popover';
import React from 'react';
import { useUser } from '../../../../auth/OidcProvider';
import MenuWrapper, { MenuItem } from './AvatarMenu.styled';

type AvatarMenuType = {
  // name?: string;
  // src?: string;
  placement?: any;
  showOnlyMenu?: boolean;
  onClick?: () => void;
};

type MenuType = {
  onClick?: () => void;
};

const Menu = ({ onClick }: MenuType) => {
  return (
    <MenuWrapper>
      <li>
        <MenuItem onClick={onClick} to="/logout">Logout</MenuItem>
      </li>
    </MenuWrapper>
  );
};

const AvatarMenu = ({
  placement,
  showOnlyMenu,
  onClick,
}: AvatarMenuType) => {
  const user = useUser();

  return (
    <>
      {showOnlyMenu ? (
        <Menu onClick={onClick} />
      ) : (
        <StatefulPopover
          content={<Menu onClick={onClick} />}
          placement={placement ? placement : 'bottomRight'}
          overrides={{
            Inner: {
              style: ({ $theme }) => {
                return {
                  backgroundColor: $theme.colors.primaryB,
                };
              },
            },
          }}
          showArrow
        >
          <Block overrides={{ Block: { style: { cursor: 'pointer' } } }}>
            <Avatar src={user?.profile.picture} name={user?.profile.name || ''} size="scale1000" />
          </Block>
        </StatefulPopover>
      )}
    </>
  );
};

export default AvatarMenu;
