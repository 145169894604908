import React from 'react';
import { Input } from 'baseui/input';
import { Block } from 'baseui/block';
import { Search } from 'baseui/icon';

interface Props {
  onChange: (value: string) => void
  value: string
}
export const SearchField: React.FC<Props> = ({ onChange, value }) => {
  const After = () => (
    <Block
      overrides={{
        Block: {
          style: ({ $theme }) => {
            return {
              display: 'flex',
              alignItems: 'center',
              paddingLeft: $theme.sizing.scale500,
              paddingRight: $theme.sizing.scale500,
            };
          },
        },
      }}
    >
      <Search size="24px" />
    </Block>)

  return (
    <Input
      overrides={{ After }}
      placeholder="Search"
      value={value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
    />)
}