import gql from 'graphql-tag'
import moment from 'moment'
import React, { useState } from 'react'
import { useSubscription } from 'react-apollo'
import { GraphqlError } from '../../components/Graphql/GraphqlError'
import { GraphqlLoading } from '../../components/Graphql/GraphqlLoading'
import { SimplePage } from '../../components/Page/Page'
import { ServiceCard, ServiceCardFragment } from '../../components/Service/ServiceCard'
import { ServiceContainer } from '../Customer/Customer.styled'
import { GetServices } from './types/GetServices'
import { SearchContainer } from './Services.styled'
import { SearchField } from '../../components/SearchField/SearchField'

const GET_SERVICES = gql`
  subscription GetServices($from: timestamptz, $until: timestamptz) {
    service(order_by: [{customer_key: asc}, {key: asc}]) {
      ...ServiceCardParts
    }
  }
  ${ServiceCardFragment}
`



export const Services = () => {
  const [searchText, setSearchText] = useState<string>('')

  const coeff = 1000 * 60 * 5 // round time to 5 minutes to avoid missing all caches
  const now = new Date((Math.floor(new Date().getTime() / coeff) - 1) * coeff)
  const { loading, error, data } = useSubscription<GetServices, {}>(GET_SERVICES, {
    variables: {
      until: moment(now).format(),
      from: moment(now).subtract(1, 'month').format()
    }
  })
  if (loading) return <GraphqlLoading />
  if (error) return <GraphqlError error={error} />

  const matches = (value: string | null | undefined) => value && value.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
  const services = data?.service || []
  return (
    <SimplePage>
      <SearchContainer>
        <SearchField value={searchText} onChange={setSearchText} />
      </SearchContainer>

      <ServiceContainer>
        {services.filter(s => matches(s.key) || matches(s.contractItem?.name)).map(s => <ServiceCard service={s} key={s.key} />)}
      </ServiceContainer>
    </SimplePage>
  )
}