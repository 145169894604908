import gql from 'graphql-tag'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Card } from '../../components/Card/Card'
import { ServiceIcon } from '../../components/Service/ServiceIcon'
import { AvailabilityPercentage } from './AvailabilityPercentage'
import { ServiceCardParts } from './types/ServiceCardParts'

export const ServiceCardFragment = gql`
  fragment ServiceCardParts on service {
    key
    type
    hostname
    contractItem {
      name
      start
      details
      contract {
        key
      }
    }
    availability(args: {from: $from, until: $until}) {
      up_fraction
      unaccounted_seconds
    }
  }
`

export const ServiceCard: React.FC<{ service: ServiceCardParts }> = ({ service }) => {
  const history = useHistory()
  const item = service.contractItem
  const details = <>
    {item && <div>{item.name}</div>}
    {service.hostname && <div><a href={`https://${service.hostname}`}>{service.hostname}</a></div>}
    {item && item.details && <div>{item.details}</div>}
    {item && (<div>in <Link to={`/contract/${item.contract.key}`}>{item.contract.key}</Link> since {item.start}</div>)}
  </>
  const avail = service.availability && service.availability[0]
  const uptime = <AvailabilityPercentage periodName="last 30 days" data={avail}/>
  const open = () => history.push(`/service/${service.key}`)
  const buttons = []
  if (service.hostname) buttons.push({ text: 'Open', onClick: () => window.open(`https://${service.hostname}`, '_blank') })
  return <Card
    name={service.key}
    details={details}
    label={uptime || '-'}
    icon={<ServiceIcon type={service.type} />}
    onHeaderClick={open}
    buttons={buttons}
    style={{ float: 'left' }}
    contentStyle={{ minHeight: '10.5em' }}
  />
}
