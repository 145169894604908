import { styled } from 'styletron-react';

export const SlrContent = styled('div', {
  marginBottom: '20px'
})
export const SlaInfos = styled('div', {
  marginBottom: '10px'
})
export const SlaContract = styled('div', {
})
export const SlaDetails = styled('div', {
})

export const SlrReporting = styled('div', {
})
export const SlrPeriod = styled('div', {
  marginTop: '5px',
  fontSize: 'x-small',
})
export const SlrTable = styled('table', {
  marginTop: '10px',
  marginBottom: '20px'
})
export const AvailabilityCell = styled('td', {
  textAlign: 'right',
})
export const SupportCell = styled('td', {
  textAlign: 'right',
})
export const IntervalNameHead = styled('th', {
  textAlign: 'right',
})
export const IntervalDetailHead = styled('th', {
  fontSize: 'x-small',
  fontWeight: 'normal',
  textAlign: 'right',
  minWidth: '18em',
})
export const ItemNameHead = styled('th', {
  textAlign: 'right',
  minWidth: '10em',
})

export const TooltipContent = styled('div', {
})
export const TooltipSection = styled('div', {
  marginTop: '3px',
  marginBottom: '3px',
})
export const TicketList = styled('ul', {
  listStyle: 'none',
  paddingLeft: '5px',
})
