import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useSubscription } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { Card } from '../../components/Card/Card';
import { GraphqlError } from '../../components/Graphql/GraphqlError';
import { GraphqlLoading } from '../../components/Graphql/GraphqlLoading';
import { SimplePage } from '../../components/Page/Page';
import { BoxContainer, SearchContainer } from './Customers.styled';
import { SearchField } from '../../components/SearchField/SearchField';
import { GetCustomers, GetCustomers_customer } from './types/GetCustomers';

const GET_CUSTOMERS = gql`
  subscription GetCustomers {
    customer(order_by: {key: asc}) {
      key
      name
    }
  }
`

interface CustomerProps {
  value: GetCustomers_customer
}
const Customer: React.FC<CustomerProps> = ({ value }) => {
  const history = useHistory();
  const open = () => history.push(`/customer/${value.key}`)
  return (
    <Card
      key={value.key}
      name={value.name || value.key}
      details="Jira, Confluence"
      label="99.54%"
      onHeaderClick={open}
      style={{ float: 'left' }}
    />
  )
}

interface CustomerListProps {
  search: string
}
const CustomerList: React.FC<CustomerListProps> = ({ search }) => {
  const { loading, error, data } = useSubscription<GetCustomers, {}>(GET_CUSTOMERS)
  if (loading) return <GraphqlLoading />
  if (error) return <GraphqlError error={error} />

  const matches = (value: string | null) => value && value.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  const customers = data?.customer.filter(c => matches(c.key) || matches(c.name)) || []
  return (
    <>
      <BoxContainer>
        {customers.map((customer) => <Customer value={customer} key={customer.key} />)}
      </BoxContainer>
    </>)
}

export const Customers: React.FC<{}> = () => {
  const [searchText, setSearchText] = useState<string>('')
  return (
    <SimplePage>
      <SearchContainer>
        <SearchField value={searchText} onChange={setSearchText} />
      </SearchContainer>

      <CustomerList search={searchText} />
    </SimplePage>
  )
}
