import cronparser from 'cron-parser'
import cronstrue from 'cronstrue'
import moment, { Moment } from 'moment'

export function formatCron(cron: string) {
    try {
        return cronstrue.toString(cron).replace('At 12:00 AM, ', '')
    } catch (e) {
        return 'invalid periodicity'
    }
}

function generateCronDates(cronExpression: string, future: number, past: number): Moment[] {
    try {
        const intervals = cronparser.parseExpression(cronExpression)
        const result: Moment[] = []
        for (let i = 0; i < future; i++) {
            result.push(moment(intervals.next().toISOString()))
        }
        for (let i = 1; i < future; i++) { intervals.prev() }
        result.reverse()
        for (let i = 0; i < past; i++) {
            result.push(moment(intervals.prev().toISOString()))
        }
        return result.reverse()
    } catch (e) {
        console.warn('Invalid CRON expression', e)
        return []
    }
}

function pairwise<A>(array: A[]): A[][] {
    const result = []
    for (var i = 0; i < array.length - 1; i++) {
        result.push([array[i], array[i + 1]])
    }
    return result
}

export interface Interval {
    start: Moment
    end: Moment
    name: string
}

export function generateCronIntervals(cronExpression: string, future: number, past: number): Interval[] {
    const dates = generateCronDates(cronExpression, future, past + 1)
    const diff = Math.max(...pairwise(dates).map(([a,b]) => b.diff(a, 'months')))
    const name = (start: Moment, end: Moment) => {
        if (diff >= 12) return start.format('YYYY')
        if (diff >= 1) return start.format('MM.YYYY')
        return start.format('DD.MM.YYYY')
    }
    return pairwise(dates).map(([start, end]) => ({
        start, end,
        name: name(start, end)
    }))
}
