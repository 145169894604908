import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import NotFound from './components/UiElements/NotFound/NotFound';
import { Customer } from './pages/Customer/Customer';
import { Customers } from './pages/Customers/Customers';
import { Home } from './pages/Home/Home';
import { Services } from './pages/Services/Services';
import { Service } from './pages/Service/Service';

export const TopRoutes: React.FC<{}> = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/home" />
      </Route>
      <Route exact path="/home">
        <Home />
      </Route>
      <Route path="/customer/:key">
        <Customer />
      </Route>
      <Route path="/customer">
        <Customers />
      </Route>
      <Route path="/service/:key">
        <Service />
      </Route>
      <Route path="/service">
        <Services />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  )
}