import React from 'react';
import Menu, { MenuItem, MenuLink } from './MainMenu.styled';

const menuItems = [
  {
    id: 1,
    label: 'Overview',
    path: '/home',
  },
  {
    id: 2,
    label: 'Customers',
    path: '/customer',
  },
  {
    id: 3,
    label: 'Bills',
    path: '/bill',
  },
  {
    id: 4,
    label: 'Services',
    path: '/service',
  },
  {
    id: 5,
    label: 'Contracts',
    path: '/contract',
  },
  {
    id: 6,
    label: 'Clusters',
    path: '/cluster',
  },
  {
    id: 7,
    label: 'Pricing',
    path: '/pricing',
  },
];

type MenuProps = {
  className?: string;
  onClick?: () => void;
};

const MainMenu = ({ className, onClick }: MenuProps) => {
  return (
    <Menu className={className}>
      {menuItems.map(item => (
        <MenuItem key={`top-menu--item${item.id}`} onClick={onClick}>
          <MenuLink to={item.path} activeClassName="active">{item.label}</MenuLink>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default MainMenu;
