import { StatefulTooltip } from 'baseui/tooltip'
import moment from 'moment'
import React from 'react'
import { Indicator } from '../Indicator/Indicator'

interface Data {
    up_fraction: number | null;
    unaccounted_seconds: number | null;
}

interface Props {
    periodName: string
    data: Data | null
    hangingIndicator?: boolean
}

export const AvailabilityPercentage: React.FC<Props> = ({ periodName, data, hangingIndicator }) => {
    if (!data || !data.up_fraction) {
        return <span>'-'</span>
    }
    const missingAvailData = data.unaccounted_seconds == null || data.unaccounted_seconds > 5 * 60

    const tooltip = (
        <>
            <div>{periodName}</div>
            {missingAvailData && <div>Warning: {moment.duration(data.unaccounted_seconds || 0, 'seconds').humanize()} of missing data</div>}
        </>
    )
    return (
        <StatefulTooltip content={tooltip}>
            <span>
                {(data.up_fraction * 100).toFixed(2)}%
                {missingAvailData ? <Indicator type="warn" hanging={hangingIndicator} /> : ''}
            </span>
        </StatefulTooltip>
    )
}