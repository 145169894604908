import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Client } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import './App.css';
import { OidcProvider } from './auth/OidcProvider';
import { RequireLoggedIn } from './auth/RequireLoggedIn';
import { Layout } from './components/Layout/Layout';
import { Apollo } from './Apollo';
import { TopRoutes } from './TopRoutes';
import { BaseProvider, LightTheme } from 'baseui';

function webappRoot() {
  return `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}`: ''}`
}

function App() {
  const styletron = new Client()
  const root = webappRoot()
  return (
    <div className="App">
      <OidcProvider applicationRoot={root}>
        <RequireLoggedIn>
          <Apollo url={root}>
            <StyletronProvider value={styletron}>
              <BaseProvider theme={LightTheme}>
                <BrowserRouter>
                  <Layout>
                    <TopRoutes />
                  </Layout>
                </BrowserRouter>
              </BaseProvider>
            </StyletronProvider>
          </Apollo>
        </RequireLoggedIn>
      </OidcProvider>
    </div>
  );
}

export default App;
