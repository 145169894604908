import React from 'react';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { setContext } from 'apollo-link-context';
import { useUser } from './auth/OidcProvider';
import { ApolloProvider } from "react-apollo";

const resolvers = {}

function createApollo(url: string, jwt: string) {
  if (!url || !jwt)
    throw new Error('missing configuration for apollo')
  console.log(`Using graphql source at ${url}`)

  const updateAuthCookie = () => {
    // we use a short lived cookie because websocket does not support headers
    //  (well it kinda does when passed in the first message, but oathkeeper
    //   does not understand that)
    document.cookie = `bearer_token=${jwt}; Path=/v1/graphql; SameSite=Strict; Max-Age=300;`
  }
  updateAuthCookie()

  const httpLink = new HttpLink({
    uri: url + '/v1/graphql',
  });
  const websocketLink = new WebSocketLink({
    uri: url.replace(/^http:\/\//g, 'ws://').replace(/^https:\/\//g, 'wss://') + '/v1/graphql',
  });
  const authLink = setContext(() => {
    updateAuthCookie()
    return {}
  });

  return new ApolloClient({
    link: authLink.concat(websocketLink || httpLink),
    cache: new InMemoryCache(),
    resolvers,
  });
}

interface Props {
  url: string
  children?: any
}
export const Apollo: React.FC<Props> = ({ url, children }) => {
  const user = useUser();
  if (!user) return <></>

  const client = createApollo(url, user.id_token)
  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  )
}
