import { AuthenticationProvider, useReactOidc } from '@axa-fr/react-oidc-context';
import React from 'react';

interface User {
  id_token: string
  scope: string
  profile: {
    sub: string
    azp?: string
    hd?: string // hosted domain
    name?: string
    given_name?: string
    family_name?: string
    email?: string
    picture?: string
    locale?: string
  }
}
export function useUser(): User | undefined {
  const oidc = useReactOidc()
  return oidc && oidc.oidcUser
}


interface Props {
  applicationRoot: string
  children: any
}

export const OidcProvider: React.FC<Props> = ({ applicationRoot, children }) => {
  const oidcSettings = {
    authority: 'https://accounts.google.com/',
    client_id: '687807728539-cqhpja0fvmc9rc1iuk71terktlrrgqi1.apps.googleusercontent.com',
    response_type: 'id_token',
    scope: 'openid profile email',
    redirect_uri: `${applicationRoot}/authentication/callback`,
    silent_redirect_uri: `${applicationRoot}/authentication/silent_callback`,
    post_logout_redirect_uri: `${applicationRoot}/`,
    automaticSilentRenew: true,
    loadUserInfo: true,
    triggerAuthFlow: false
  }

  const inProgress = () => <div>Authentication in progress</div>
  const notAuthenticated = () => <div>Not Authenticated.</div>
  const notAuthorized = () => <div>Not Authorized.</div>
  const sessionLost = () => <div>Session expired.</div>
  const callback = () => <div>Authentication in progress</div>

  return (
    <div className="App">
      <AuthenticationProvider configuration={oidcSettings}
        authenticating={inProgress}
        notAuthenticated={notAuthenticated}
        notAuthorized={notAuthorized}
        sessionLostComponent={sessionLost}
        callback={callback}>
        {children}
      </AuthenticationProvider>
    </div>
  );
}
