import { styled } from 'styletron-react';

export const SearchContainer = styled('div', {
  paddingBottom: '30px',
})

export const BoxContainer = styled('div', {
  display: 'block',
  width: '100%'
})
