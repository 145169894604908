import React, { MouseEventHandler } from 'react'
import Wrapper, { Action, Button, Content, Icon, Info, Label, Title } from './Card.styled'

interface Button {
  text: string
  onClick: () => void
  color?: string
}
interface CardProps {
  name: string
  label?: React.ReactNode
  details?: React.ReactNode
  icon?: React.ReactNode
  color?: string
  onHeaderClick?: () => void
  buttons?: Button[]
  style?: React.CSSProperties
  contentStyle?: React.CSSProperties
}

export const Card: React.FC<CardProps> = ({
  name,
  label,
  details,
  icon,
  color,
  buttons,
  onHeaderClick,
  style,
  contentStyle,
}) => {
  const headerClick: MouseEventHandler<HTMLDivElement> = (e) => {
    onHeaderClick && onHeaderClick()
    e.preventDefault()
  }
  return (
    <Wrapper style={style}>
      <Content style={contentStyle}>
        {icon && <Icon $color={color} onClick={headerClick}>{icon}</Icon>}
        <Info>
          <Title onClick={headerClick}>{name}</Title>
          <Label>{details}</Label>
        </Info>
      </Content>
      {buttons || label ? (
        <Action>
          {buttons?.map(b =>
            <Button $color={b.color || color} onClick={b.onClick} key={b.text}>
              {b.text}
            </Button>
          )}
          <Label>{label}</Label>
        </Action>
      ) : (
          ''
        )}
    </Wrapper>
  )
}
