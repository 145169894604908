import { styled } from 'baseui';

const Wrapper = styled('span', {
  display: 'inline-flex',
  alignItems: 'center',
});

export const LogoText = styled('span', {
  textDecoration: 'none',
  color: 'black',
  marginLeft: '11px',
  fontFamily: "'Exo 2' !important",
  fontSize: '18px'
})

export default Wrapper;
