import { ApolloError } from 'apollo-client'
import React from 'react'

interface Props {
  error: ApolloError
}
export const GraphqlError: React.FC<Props> = ({ error }) => {
  return (
    <div>Error: ${error.message}</div>
  )
}
