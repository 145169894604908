import React from 'react'
import { Button } from './Button.styled'
import { AiOutlineEdit, AiOutlineSave } from 'react-icons/ai'

interface Props {
  onClick: () => void
  style?: React.CSSProperties
}

export const EditButton: React.FC<Props> = ({ onClick, style }) =>
  <Button onClick={onClick} style={style}>
    <AiOutlineEdit size={'24px'} />
  </Button>

export const SaveButton: React.FC<Props> = ({ onClick, style }) =>
  <Button onClick={onClick} style={style}>
    <AiOutlineSave size={'24px'} />
  </Button>
