import { styled } from 'styletron-react';

export const Icon = styled('div', (props: any) => ({
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: props.$color ? props.$color : '#FF0080',
}));