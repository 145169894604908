import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Block } from 'baseui/block';
import Container from '../UiElements/Container/Container';
import PageTitle, { PageTitleProps } from '../UiElements/PageTitle/PageTitle';

interface Props {
  children?: any
}

export const PageContainer: React.FC<Props> = ({ children }) =>
  <Container>
    <Block paddingTop={['15px', '20px', '30px', '40px']}>{children}</Block>
  </Container>

export const SimplePage: React.FC<Props> = ({ children }) =>
  <PageContainer>
    <Row><Col lg={12}>
      <Block paddingTop={['10px', '10px', 0]} paddingBottom="25px">
        {children}
      </Block>
    </Col></Row>
  </PageContainer>

export const PageWithTitle: React.FC<Props & PageTitleProps> = ({ children, ...props }) =>
  <>
    <PageTitle {...props} />
    <Container>{children}</Container>
  </>
