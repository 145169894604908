import gql from 'graphql-tag';
import moment from 'moment';
import React from 'react';
import { useMutation, useSubscription } from 'react-apollo';
import { Row } from 'react-flexbox-grid';
import { useParams } from 'react-router-dom';
import { GraphqlError } from '../../components/Graphql/GraphqlError';
import { GraphqlLoading } from '../../components/Graphql/GraphqlLoading';
import { InlineEditable } from '../../components/InlineEditable/InlineEditable';
import { PageWithTitle } from '../../components/Page/Page';
import { ServiceCard, ServiceCardFragment } from '../../components/Service/ServiceCard';
import NotFound from '../../components/UiElements/NotFound/NotFound';
import { ServiceContainer } from './Customer.styled';
import { ServiceLevelReport, ServiceLevelReportFragment } from './ServiceLevelReport';
import { GetCustomer } from './types/GetCustomer';
import { UpdateCustomerName } from './types/UpdateCustomerName';

const GET_CUSTOMER = gql`
  subscription GetCustomer($key: String!, $from: timestamptz, $until: timestamptz) {
    customer_by_pk(key: $key) {
      key
      name
      services(order_by: {key: asc}) {
        ...ServiceCardParts
      }
      contracts {
        activeVersion {
          items(where: {item: {serviceLevelReporting: {id: {_is_null: false}}}}) {
            item {
              serviceLevelReporting {
                id
                ...ServiceLevelReportParts
              }
            }
          }
        }
      }
    }
  }
  ${ServiceLevelReportFragment}
  ${ServiceCardFragment}
`

const UPDATE_NAME = gql`
  mutation UpdateCustomerName($key: String!, $name: String) {
    update_customer(where: {key: {_eq: $key}}, _set: {name: $name}) {
      affected_rows
    }
  }
`

export const Customer: React.FC<{}> = () => {
  const { key } = useParams<{ key: string }>()
  const coeff = 1000 * 60 * 5 // round time to 5 minutes to avoid missing all caches
  const now = new Date((Math.floor(new Date().getTime() / coeff) - 1) * coeff)
  const { loading, error, data } = useSubscription<GetCustomer, {}>(GET_CUSTOMER, {
    variables: {
      key,
      until: moment(now).format(),
      from: moment(now).subtract(1, 'month').format()
    }
  })
  const [updateCustomerName] = useMutation<UpdateCustomerName>(UPDATE_NAME)
  if (loading) return <GraphqlLoading />
  if (error) return <GraphqlError error={error} />

  const customer = data?.customer_by_pk
  if (!customer) return <NotFound />
  const name = customer.name || customer.key

  const updateName = (newName: string) => {
    updateCustomerName({ variables: { key, name: newName } })
  }
  const title = (
    <span style={{ display: 'flex', width: '100%' }}>
      Customer <InlineEditable value={name} save={updateName} style={{ marginLeft: '0.75em', width: 'min(100%, 700px)' }} />
    </span>)

  const slrs = customer.contracts.flatMap(c => c.activeVersion?.items).flatMap(i => i?.item.serviceLevelReporting)

  return (
    <PageWithTitle title={title} >
      <Row>
        <h2>Services</h2>
      </Row>
      <Row>
        <ServiceContainer>
          {customer.services.map(s => <ServiceCard service={s} key={s.key} />)}
        </ServiceContainer>
      </Row>

      <Row>
        <h2>Service Level Reporting</h2>
      </Row>
      <Row>
        {slrs.map(slr => slr && <ServiceLevelReport slr={slr} key={slr.id} />)}
      </Row>
    </PageWithTitle>
  )
}
