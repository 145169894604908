import React from 'react';

type SvgIconProps = {
  style?: React.CSSProperties;
  children: any;
};

const SvgIcon = ({ children, style }: SvgIconProps) => {
  return (
    <span
      style={style}
      className="svg-icon"    >
      {children}
    </span>
  );
};

export default SvgIcon;
